import jwt from 'jsonwebtoken';

import { UseLocalStorage } from 'lib/hooks/useLocalStorage';

export const isTokenExpired = (token: string = UseLocalStorage().getToken()): boolean => {
    try {
        const { exp } = jwt.decode(token) as {
            exp: number;
        };
        const expirationDatetimeInSeconds = exp * 1000;
        return Date.now() >= expirationDatetimeInSeconds;
    } catch {
        return true;
    }
};
