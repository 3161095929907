import { Routes } from 'constants/navigation';
import jwt from 'jsonwebtoken';
import { UseLocalStorage } from 'lib/hooks/useLocalStorage';

export const getMatchingRoute = (token: string = UseLocalStorage().getToken()): string => {
    const { organizations } = jwt.decode(token) as {
        organizations: {
            role: string;
            organizationId: string;
        }[];
    };
    return organizations !== undefined ? Routes.Dashboard : Routes.ProviderDashboard;
};
