import { Routes } from 'constants/navigation';
import { useAuth } from 'context/authContext';
import { isTokenExpired } from 'lib/utils/auth';
import { getMatchingRoute } from 'lib/utils/navigation';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useTranslation } from '../lib/hooks/useTranslation';
import styles from '../styles/Home.module.css';

const Home: NextPage = () => {
    const t = useTranslation();
    const router = useRouter();
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        if (isLoggedIn() && !isTokenExpired()) {
            router.push(getMatchingRoute());
        } else {
            router.push(Routes.Signup);
        }
    }, []);

    return (
        <div className={styles.container}>
            <Head>
                <title>{t.home.title}</title>
            </Head>
        </div>
    );
};

export default Home;
